import { fetchViaProxy } from './proxy.service';

export interface RssItem {
  title: string;
  date: Date;
  link: string;
  imgSrc?: string;
  imgObjectUrl?: string;
  description: string;
}

export default {
  parseFeed(url: string, limit?: number): Promise<RssItem[]> {
    return (
      fetchViaProxy(url)
        .then((response) => response.text())
        .then((xmlString) => {
          const domParser = new DOMParser();
          const doc = domParser.parseFromString(xmlString, 'text/xml');
          return Array.from(doc.querySelectorAll('item')).map((item) => {
            // extract title
            const title = (item.querySelector('title') || ({} as any))
              .textContent;
            // extract link url
            const link = (item.querySelector('link') || ({} as any))
              .textContent;
            // extract date
            const dateString = (item.querySelector('pubDate') || ({} as any))
              .textContent;
            const date = new Date(dateString);
            // extract image src
            const imgSrcNode = item.querySelector('enclosure') || ({} as any);
            const imgSrc =
              imgSrcNode &&
              imgSrcNode.attributes &&
              imgSrcNode.attributes.url &&
              imgSrcNode.attributes.url.value;
            // extract description
            const descriptionHtml = (
              item.querySelector('description') || ({} as any)
            ).textContent;
            const description = domParser.parseFromString(
              descriptionHtml,
              'text/html',
            ).body.textContent;

            return {
              title,
              link,
              date,
              imgSrc,
              description,
            } as RssItem;
          });
        })
        // limit items
        .then((items) => (limit ? items.slice(0, limit) : items))
        // load images via proxy
        .then((items) => {
          const result = items.map(async (item) => {
            return {
              ...item,
              imgObjectUrl: item.imgSrc
                ? await fetchViaProxy(item.imgSrc)
                    .then((response) => response.blob())
                    .then((imageBlob) => {
                      try {
                        // eslint-disable-next-line compat/compat
                        return URL.createObjectURL(imageBlob);
                      } catch (err) {
                        return undefined;
                      }
                    })
                    .catch(() => {
                      return undefined;
                    })
                : undefined,
            } as RssItem;
          });
          return Promise.all(result);
        })
    );
  },
};
