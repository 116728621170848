import React from 'react';
import Layout from '../components/Layout/Layout';
import Offers from '../components/Offers';
import News from '../components/News';
import PageSegment from '../components/PageSegment';
import Indispensible from '../components/Indispensible';
import Shop from '../components/Shop';

export const frontmatter = {
  title: 'Willkommen',
  menu: 1,
};

export default () => (
  <Layout>
    <PageSegment>
      <h1>Herzlich Willkommen!</h1>
      <p>
        Wir begrüßen Sie in der Korbacher Hirsch-Apotheke! Bereits seit 275
        Jahren stehen wir im Dienst Ihrer Gesundheit. Bei uns erhalten Sie eine
        große Auswahl an Arzneimitteln und kompetente Beratung bei allen Fragen
        rund um Ihre Gesundheit und Ihre Arzneimittel.
        <br />
        Besuchen Sie unser Geschäft, zentral gelegen, in der Korbacher
        Stadtmitte. Dort begrüßt Sie unser Team unter der Leitung von Apotheker
        Franz Kirchner. Gemeinsam sorgen wir dafür, dass Sie schnellstmöglich
        gesund werden.
      </p>
      <p>Wir freuen uns über Ihren Besuch!</p>
    </PageSegment>

    <PageSegment>
      <Offers />
    </PageSegment>
    <PageSegment>
      <Shop />

      <Indispensible />
    </PageSegment>
    <PageSegment>
      <News />
    </PageSegment>
  </Layout>
);
